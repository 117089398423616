import React from "react";

import Layout from "../Components/Layout";
import HowItWorks from "../Components/HowItWorks";

const HowItWorksPage = () => {
  return (
    <Layout showHeader>
      <HowItWorks></HowItWorks>
    </Layout>
  );
};

export default HowItWorksPage;
